import useStyles from "./styles.js";
import { Typography } from "@material-ui/core";

const AboutMe = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.text}>
        I'm a software engineer specializing in full stack web and app development.
        I pride myself in the detail and precision I focus on while creating designs
        and optimizing user experiences.
      </Typography>
      <Typography className={classes.text}>
        I'm also a senior at <a href="https://www.olin.edu" target="_blank" rel="noreferrer">Olin College</a>, graduating in May 2025.
      </Typography>
      <Typography className={classes.text}>
        I first found my love for software development as a cofounder
        of <a href="https://www.sebela.co" target="_blank" rel="noreferrer">Sebela</a>, where I created and deployed mobile apps with Swift. Through
        my internships
        at <a href="https://www.kuvasystems.com" target="_blank" rel="noreferrer">Kuva Systems</a>{' '}
        and <a href="https://www.onshape.com/en/" target="_blank" rel="noreferrer">Onshape</a>
        , I expanded my abilities into full-stack web development.
      </Typography>

      {/* <div className={classes.learnMoreBtns}>
        <a href="https://www.sebela.co" target="_blank" rel="noreferrer">
          <Typography className={classes.buttonText}>
            More about Sebela
          </Typography>
        </a>
        <a href="https://www.kuvasystems.com" target="_blank" rel="noreferrer">
          <Typography className={classes.buttonText}>
            More about Kuva Systems
          </Typography>
        </a>
      </div> */}
    </div>
  );
};

export default AboutMe;
